import { Link, graphql } from "gatsby"

import Breadcrumb from "../components/breadcrumb"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import PropTypes from "prop-types"
import React from "react"
import Seo from "../components/seo"
import TagsList from "../components/tagslist"

const Tags = (props) => {
  const { pageContext, data, location, path } = props
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx
  const siteTitle = data.site.siteMetadata.title
  const subTitle = `Posts Tagged With "${tag}"`
  const layoutImage = data.file ? data.file.childImageSharp.gatsbyImageData : null
  const tagHeader = `${totalCount} Post${totalCount === 1 ? "" : "s"
    } Tagged With "${tag}"`

  return (
    <Layout location={location} title={siteTitle} subtitle={subTitle} image={layoutImage} meta={data.site.siteMetadata}>
      <Seo title={subTitle} />
      <h1 className="mb-5">{tagHeader}</h1>
      <Breadcrumb path={path} disableLast={true} />
      <ul className="list-unstyled">
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title, date, tags } = node.frontmatter
          const image = node.frontmatter.image ? node.frontmatter.image.childImageSharp.gatsbyImageData : null
          return (
            <li key={slug} className="mb-4">
              <Link to={slug}><h2>
                {title}
              </h2></Link>
              <p>
                <small>{date}</small>
              </p>
              <div className="row">
                <div className="col-sm order-last order-sm-first">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                  <p className="mb-4" >
                    <Link to={slug}>Read more...</Link>
                  </p>
                </div>
                {image ? <div className="col-sm order-first order-md-last">
                  <GatsbyImage image={image} className="img-thumbnail mt-0" alt="tag cloud of all blog subjects"/>
                </div> : null}
              </div>
              <div className="tags"><span>Tags:</span><TagsList tags={tags} selected={tag} /></div>
              <hr />
            </li>
          );
        })}
      </ul>
    </Layout>
  );
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    // SAS TODO: Update to match graphql query
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

// query is performed and results provided in data prop
export const pageQuery = graphql`query ($tag: String, $image: String) {
  site {
    siteMetadata {
      title
      social {
        facebook
      }
    }
  }
  file(relativePath: {eq: $image}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMdx(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {tags: {in: [$tag]}, date: {ne: null}, draft: {ne: true}}}
  ) {
    totalCount
    edges {
      node {
        excerpt
        body
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "DD MMM YYYY")
          tags
          description
          image {
           childImageSharp {
             gatsbyImageData(layout: FULL_WIDTH)
           }
          }
        }
      }
    }
  }
}
`